.App {
  min-height: 100vh;
  width: 100%;
  text-align: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-image: url(./images/bg3.jpg); 
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  p > a{
    text-decoration: none;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
  }

  
}


